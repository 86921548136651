
document.getElementById('download-form').addEventListener('submit', function(event) {
    event.preventDefault(); // Prevent form submission
  
    // Validate form inputs
    var email = document.getElementById('download-email').value.trim();
    console.log(email);
    // Email validation using a regular expression
    var emailRegex = /^\S+@\S+\.\S+$/;
    if (!email || !emailRegex.test(email)) {
      alert('Please fill in all fields with valid inputs.');
      return;
    };
  
    // Construct the form data
    var download_id = 'wb_10.22.11.pdf';
    var base_url = "https://api.wassimbejaoui.com/download?id=";
    var url_download = base_url + download_id + "&email=" + email;
    // Perform form submission
    download_from_s3_get(url_download);
  });



  function download_from_s3_get(url_download) {
    var config = {
      method: 'get',
      responseType: 'blob',
      url: url_download,
      headers: { }
    };
    // Run the URL
    axios(config)
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            // Set the download attribute to the desired filename
            link.setAttribute('download', 'wassimbejaoui_cv.pdf');
            //link.setAttribute('download', elem.id);
            document.body.appendChild(link);
            link.click();
            // Clear the form after successful submission
            document.getElementById('download-form').reset();
          })
          .catch(function(error) {
            console.error(error);
            alert('Download failed. Please try again later.');
            document.getElementById('download-form').reset();
          });
  }